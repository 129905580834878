<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">Demande de recours <br /></h2>
        <b-button
          variant="outline-primary"
          class="d-flex align-items-center"
          @click="$router.push({ name: 'appeal-list' })"
        >
          <i class="las la-arrow-left mr-50" />
          <span>{{ $t('button.back_to_list') }}</span>
        </b-button>
      </div>
    </b-card>

    <b-card>
      <validation-observer ref="formRules">
        <b-row>
          <b-col md="6">
            <b-form-group label-cols-md="12" label-for="title">
              <template v-slot:label>
                <span
                  >Nom du demadeur
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="title"
                  v-model="username"
                  type="text"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="profils" label-cols-md="12">
              <template v-slot:label>
                <span
                  >{{ $t('appeals.search_requests_associed') }}
                  <RequiredStar />
                </span>
              </template>
              <b-form-input
                v-model="reference_demande"
                class=""
                type="text"
                :placeholder="$t('appeals.create_placeholder_request')"
                @change="reference_demande != '' ? load_requests() : ''"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <h3>{{ $t('appeals.create_info') }}</h3>
            <b-card-body v-if="requests_table_data.length !== 0">
              <b-spinner v-if="isLoading == true" small />

              <div v-else class="demandes">
                <div
                  v-for="(demande, indexD) in requests_table_data"
                  :key="indexD"
                >
                  <h6>
                    <strong>{{ $t('demand_detail.reference') }}</strong>
                    {{ demande && demande.reference }}
                  </h6>
                  <h6>
                    <strong>{{ $t('demand_detail.esta') }}</strong
                    ><span>{{ demande && demande.raison_sociale }}</span>
                  </h6>
                  <h6>
                    <strong>{{ $t('demand_detail.pro') }}: </strong>
                    {{ demande && demande.nom_promoteur }}
                    {{ demande && demande.prenom_promoteur }}
                  </h6>
                  <h6>
                    <strong>{{ $t('demand_detail.type_request') }}</strong>
                    {{
                      $t(getTypeDemandebyCode(demande && demande.code_demande))
                    }}
                  </h6>
                  <h6>
                    <strong>{{ $t('demand_detail.date') }}</strong>
                    {{ demande && demande.created_at }}
                  </h6>
                </div>
              </div>
            </b-card-body>
            <b-card-body v-else>
              <div>
                <p>{{ $t('appeals.no_demande') }}</p>
              </div>
            </b-card-body>
          </b-col>

          <b-col md="12">
            <b-form-group label-cols-md="12" label-for="descripion">
              <template v-slot:label>
                <span
                  >{{ $t('appeals.create_message') }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-textarea
                  v-model="form.message"
                  :placeholder="$t('appeals.create_message_placeholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('claimsPage.attachedFiles')"
              label-for="jointFiles"
            >
              <b-form-file
                id="jointFiles"
                v-model="form.jointFiles"
                multiple
                :placeholder="$t('claimsPage.attachedFilesPlaceholder')"
                :drop-placeholder="
                  $t('claimsPage.attachedFilesPlaceholderDrop')
                "
                @change="handleFileChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button
            variant="primary"
            :disabled="isCreatingProduct"
            @click="createAppeal()"
          >
            <feather-icon
              v-if="isCreatingProduct == false"
              icon="SaveIcon"
            /><b-spinner v-if="isCreatingProduct == true" small /> &nbsp;
            {{ $t('appeals.create_form_button') }}
          </b-button>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner,
  BCardHeader,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import appealsStoreModules from '@/store/appeals'
import secteursStoreModule from '@/store/secteurs'
import rolesStoreModule from '@/store/roles'
import RequiredStar from '@/components/RequiredStar.vue'
import formulairesStoreModule from '@/store/formulaire'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTags,
    BFormTextarea,
    BFormFile,
    VueEditor,
    vSelect,
    BSpinner,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    RequiredStar,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'appeals', module: appealsStoreModules },
      { path: 'secteurs', module: secteursStoreModule },
      { path: 'roles', module: rolesStoreModule },
      { path: 'formulaires', module: formulairesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  data() {
    return {
      listSecteurs: [],
      listRoles: [],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'link'],
      ],
      username: localstorageService.getUserData().fullname,
      form: {
        user_id: localstorageService.getUserData().id,
        demande_id: null,
        message: null,
        jointFiles: null,
      },
      isCreatingProduct: false,
      isFetchingSecteur: false,
      reference_demande: '',
      requests_table_data: [],
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        //  await this.loadRoles()
      },
    },
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('appeals', {
      action_createAppeal: 'createAppeal',
    }),
    ...mapActions('formulaires', {
      action_searchFormulaires: 'searchFormulaires',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    ...mapActions('roles', {
      action_fetchRoles: 'fetchRoles',
    }),
    async loadRoles() {
      try {
        const response = await this.action_fetchRoles()
        console.log('response::: ', response)
        if (response.status === 200) {
          this.listRoles = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des roles', error)
      }
    },
    load_requests() {
      this.isLoading = true
      const params = {
        reference: this.reference_demande,
        page: 1,
        perPage: 1000000000,
      }
      // console.log('⭕️load_requests params::: ', params);
      this.action_searchFormulaires(params)
        .then((res) => {
          // console.log('⭕️res::: ', res.data.data);
          if (res.status === 200) {
            this.isLoading = false
            this.requests_table_data = []
            res.data.data.forEach((item) => {
              console.log('⭕️⭕️⭕️ item::: ', item)
              const slug = utilsService.getSlugByCodeDemande(
                item.type_demande.code
              )
              const selectedForm = utilsService.getFormulaireByCodeClient(slug)
              let label = null
              switch (selectedForm.sector) {
                case 'UEH':
                  label = `Référence demande: ${item.reference} --- Établissement: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break
                case 'UEAV':
                  label = `Référence demande: ${item.reference} --- Agence: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break
                case 'UEGT':
                  label = `Référence demande: ${item.reference} --- Guide: ${item.user?.firstname} ${item.user.lastname} --- Demande: ${item.type_demande.title}`
                  break
                case 'UER':
                  label = `Référence demande: ${item.reference} --- Établissement: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break
                default:
                  break
              }

              const build_object = {
                ...item.data,
                selectedForm,
                slug,
                user: item.user,
                user_id: item.user_id,
                id: item.id,
                statuses: item.statuses,
                created_at: item.created_at,
                updated_at: item.updated_at,
                duration: item.type_demande.duration,
                type_demande: item.type_demande,
                auto_evaluation: item.auto_evaluation,
                audit: item.audit,
                reference: item.reference,
                code_demande: item.type_demande.code,
                label,
                user: item.user,
              }

              this.requests_table_data.push(build_object)
            })
            // console.log('this.requests_table_data::: 🟢⭕️🟢', this.requests_table_data);
            // --------------------------------------------------------------
            this.paginationData.total = res.data.meta.total || 0
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from =
                this.paginationData.perPage * this.paginationData.currentPage -
                this.paginationData.perPage
            }
            this.paginationData.metaData.to =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage +
              res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
    handleFileChange(event) {
      // Store selected files in the formData
      this.form.jointFiles = Array.from(event.target.files)
    },
    getTypeDemandebyCode(code) {
      const selectedForm = utilsService.getFormulaireByCodeDemande(code)
      return selectedForm.title
    },
    fetchSecteurs() {
      this.isFetchingSecteur = true
      this.action_fetchFaqSections()
        .then((response) => {
          this.isFetchingSecteur = false
          this.listSecteurs = response.data.data
          // console.log('this.formload11111: ', this.listSecteurs)
        })
        .catch((error) => {
          this.isFetchingSecteur = false
        })
    },
    async createAppeal() {
      let demande_id = await this.requests_table_data[0].id
      await this.$refs.formRules
        .validate()
        .then((success) => {
          this.isCreatingProduct = true
          this.form.demande_id = demande_id

          const formData = new FormData()

          formData.append('message', this.form.message)

          formData.append('demande_id', demande_id)
          formData.append('user_id', this.form.user_id)

          // Append files to the FormData object
          if (this.form.jointFiles) {
            for (const file of this.form.jointFiles) {
              formData.append('attached_files[]', file)
            }
          }
          console.log('this.form: ', this.requests_table_data[0].id)
          this.action_createAppeal(formData)
            .then((response) => {
              if (response.status === 201) {
                this.isCreatingProduct = false

                this.$router.replace({ name: 'appeal-list' }).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Création réussie',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '',
                    },
                  })
                })
              }
            })
            .catch((error) => {
              // console.log(error)
              this.isCreatingProduct = false
              // this.errored = true
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Une erreur est survenue',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: '',
                },
              })
            })
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
.demandes {
  border: 1px solid #286d64;
  border-radius: 10px;
  // box-shadow: 1px 1px 3px #dee2e6;
  padding: 10px 15px;
  cursor: pointer;
}
</style>
